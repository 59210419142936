import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'amfe-flexible';
import i18n from '@/language/';
import dataV from '@jiaminghi/data-view'

createApp(App)
.use(dataV)
.use(store)
.use(i18n)
.use(router)
.mount('#app');
