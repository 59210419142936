import axios from "../request";

//登录接口
export const loginApi = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/head/login",
        data: data,
    });
//修改接口
export const updateUserApi = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/head/update",
        data: data,
    });
    export const userUpdatePwdCode = (data,sms) =>
        axios({
            method: "post",
            url: "/qblltPE/head/update_pwd_code/"+sms,
            data: data,
        });
// 转账
export const upbaozhu = (data, state) =>
    axios({
        method: "post",
        url: "/qblltPE/head/set_baozhu/" + state,
        data: data,
    });
// 转账明细
export const getZZList = (userId) =>
    axios.get("/qblltPE/income/user_zz/" + userId);
//注冊接口
export const registerApi = (data,sms) =>
    axios({
        method: "post",
        url: "/qblltPE/head/register/"+sms,
        data: data,
    });
export const senCode = (phone,state) =>
    axios.get("/qblltPE/head/send_code/" + phone+"/"+state);
//上传文件
export const upFile = (data) =>
    axios({
        method: "post",
        headers: {
            "content-type": "multipart/form-data",
        },
        url: "/common/upload",
        data: data,
    });
export const upFileTo = (data) =>
    axios({
        method: "post",
        headers: {
            "content-type": "multipart/form-data",
        },
        url: "/qblltPE/qbt_user/avatar",
        data: data,
    });
//修改用户
export const updateUser = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_user/update_user",
        data: data,
    });

//新增提现记录
export const addWithdrawal = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_user/add_withdrawal",
        data: data,
    });

export const getZsyIncome = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/income/user_zsy_income",
        data: data,
    });
export const addAd = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_ad/add_ad",
        data: data,
    });
export const adUpdate = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_ad/update",
        data: data,
    });
export const getAdList = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_ad/list",
        data: data,
    });
// 查询用户提现
export const selectWithdrawal = (userId) =>
    axios.get("/qblltPE/qbt_user/select_withdrawal/" + userId);

// 兑换旅游卡
export const addUserLyk = (userId, cards) =>
    axios.get("/qblltPE/qbt_user/addUserLyk/" + userId + "/" + cards);
// 查询旅游卡
export const userCardList = (userId) =>
    axios.get("/qblltPE/qbt_user/userCardList/" + userId);
export const updateTravelCards = (cardId) =>
    axios.get("/qblltPE/qbt_user/updateTravelCards/" + cardId);

// 查询商品
export const getShopList = (state) =>
    axios.get("/qblltPE/qbllt_shop/list/" + state);
// 查询旅游卡商品
export const getCouponList = () => axios.get("/qblltPE/qbllt_coupon/list");
// 新增旅游卡订单
export const addCouponOrder = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbllt_coupon_order/add",
        data: data,
    });

// 查询商品详细
export const getShopDetail = (shopId) =>
    axios.get("/qblltPE/qbllt_shop/detail/" + shopId);

// 查询用户地址列表
export const getAddressList = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbllt_address/list",
        data: data,
    });
// 查询地址详细
export const getAddressDetail = (adresId) =>
    axios.get("/qblltPE/qbllt_address/detail/" + adresId);

// 修改用户地址列表
export const upAddress = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbllt_address/update",
        data: data,
    });
// 新增用户地址列表
export const addAddress = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbllt_address/add",
        data: data,
    });
// 删除地址
export const delAddress = (adresId) =>
    axios.get("/qblltPE/qbllt_address/delete/" + adresId);

// 新增订单
export const addOrder = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_order/add",
        data: data,
    });
// 余额购物
export const yueUserOrder = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_order/yue_user",
        data: data,
    });
// 旅游卡购物
export const baoquanOrder = (data) =>
    axios({
        method: "post",
        url: "/qblltPE/qbt_order/dh_user",
        data: data,
    });
export const go7Pay = (orderId, payType, param) =>
    axios.get(
        "/qblltPE/qbt_order/7pay/" + orderId + "/" + payType + "/" + param
    );

// 用户开通矩阵
export const addMatrix = (userId, level, baozhu) =>
    axios.get(
        "/qblltPE/qbt_matrix/add_matrix/" + userId + "/" + level + "/" + baozhu
    );

// 查询用户正在进行中的矩阵
export const getUserMatrix = (userId) =>
    axios.get("/qblltPE/qbt_matrix/get_user_matrix/" + userId);

// 获取AI程序库
export const getAI = () => axios.get("/qblltPE/qbt_matrix/get_ai");

// 查询用户信息
export const getUserInfo = (userId) =>
    axios.get("/qblltPE/qbt_user/userinfo/" + userId);

// 查询用户团队提现信息
export const getUserTeamInfo = (userId) =>
    axios.get("/qblltPE/qbt_user/user_team/" + userId);

// 查询用户团队提现第一名
export const selectTopTeamUser = (userId) =>
    axios.get("/qblltPE/qbt_user/top_team_user/" + userId);

// 查询今日推广人数排名前100
export const selectHundredUserList = () =>
    axios.get("/qblltPE/qbt_user/hundred_user");
export const getUserTeams = (userId) =>
    axios.get("/qblltPE/qbt_user/get_user_teams/" + userId);

// 查询用户收益
export const selectUserIncomeList = (userId) =>
    axios.get("/qblltPE/income/user_income/" + userId);
