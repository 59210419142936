import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
  const _component_Floor = _resolveComponent("Floor");
  return _openBlock(), _createElementBlock(_Fragment, null, [$setup.headShow ? (_openBlock(), _createBlock(_component_Header, {
    key: 0,
    isShow: $setup.isShow
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.title), 1)]),
    _: 1
  }, 8, ["isShow"])) : _createCommentVNode("", true), _createVNode(_component_van_pull_refresh, {
    modelValue: $setup.loading,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.loading = $event),
    onRefresh: $setup.onRefresh,
    disabled: $setup.refreshDisabled
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  }, 8, ["modelValue", "onRefresh", "disabled"]), _createVNode(_component_Floor)], 64);
}