import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "floor_box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, {
    exactActiveClass: "activeA",
    to: "/"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("i", {
      class: "iconfont icon-shouye"
    }, null, -1), _createElementVNode("span", null, "首页", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    "active-class": "activeA",
    to: "/baoping"
  }, {
    default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
      class: "iconfont icon-wenhao"
    }, null, -1), _createElementVNode("span", null, "爆品区", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    to: ""
  }), _createVNode(_component_router_link, {
    class: "QIBao_box",
    "active-class": "QIBao_box_active",
    to: "/youpintuan"
  }, {
    default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("i", {
      class: "iconfont icon-chaorongheshujuzhongxin"
    }, null, -1), _createElementVNode("span", null, "秒杀", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    "active-class": "activeA",
    to: "/shop"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
      class: "iconfont icon-dibudaohanglan-"
    }, null, -1), _createElementVNode("span", null, "兑换区", -1)])),
    _: 1
  }), _createVNode(_component_router_link, {
    "active-class": "activeA",
    to: "/me"
  }, {
    default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
      class: "iconfont icon-wode"
    }, null, -1), _createElementVNode("span", null, "个人中心", -1)])),
    _: 1
  })]);
}