export default {
    state : ()=>{
        return {
            userinfo:localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")).user:[],
            student_list:localStorage.getItem("student_list") ,
            token:localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):[],
            current_student:localStorage.getItem("current_student"),
            refreshDisabled:false
        }
    },
    mutations:{
        refreshDisabled(state,refreshDisabled){
            state.refreshDisabled = refreshDisabled;
        },
        updateUserinfo(state,userinfo){
            state.userinfo = userinfo;
        },
        SET_TOKEN:(state,token)=>{
            state.token = token;
        },
        SET_STUDENT_LIST(state,list){
            state.student_list = list;
        },
        SET_COURRENT_STUDENT(state,student){
            state.current_student = student
        }
    },
    actions:{
        updateUserinfoAsync({commit},userinfo){
            setTimeout(()=>{
                commit('updateUserinfo',userinfo);
            },1000)
        },
        clearToken(context){
            context.commit("SET_TOKEN","");
        }
    },
    getters:{
        getUserinfo(state){
            return state.userinfo;
        }
    },
}