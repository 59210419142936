import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/lsxq.png';
import _imports_1 from '@/assets/csxq.png';
const _hoisted_1 = {
  class: "download_box"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("img", {
    class: "lsxq",
    src: _imports_0,
    alt: ""
  }, null, -1), _createElementVNode("img", {
    class: "csxq",
    src: _imports_1,
    alt: ""
  }, null, -1)]));
}