import Floor from "@/components/Floor.vue";
import Header from "@/components/header.vue";
import { useRouter } from "vue-router";
import { watch, ref, onMounted, loading, inject } from "vue";
import Bj from "@/components/bj.vue";
import { getUserInfo } from "@/api/getData";
import { useStore } from "vuex";

import { showNotify } from 'vant/es';
import 'vant/es/notify/style/index';
export default {
  components: {
    Floor,
    Header,
    Bj
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    let isShow = ref(false);
    let headShow = ref(false);
    let title = ref("首页");
    const reload = inject("reload");
    const loading = ref(false);
    const refreshDisabled = ref(false);
    watch(() => store.state.User.refreshDisabled, (newValue, oldValue) => {
      refreshDisabled.value = newValue;
    });
    const onRefresh = () => {
      setTimeout(() => {
        loading.value = false;
        getUser();
      }, 1000);
    };
    const getUser = async () => {
      try {
        let userId = JSON.parse(localStorage.getItem("token")).user.userId;
        let res = await getUserInfo(userId);
        if (res.data.code == 200) {
          let data = JSON.stringify(res.data.data);
          store.commit("updateUserinfo", res.data.data.user);
          store.commit("SET_TOKEN", res.data.data);
          localStorage.setItem("token", data);
          showNotify({
            type: "success",
            message: "刷新成功"
          });
          location.reload();
          reload();
        } else {
          showNotify("刷新失败");
          showNotify({
            message: res.data.msg
          });
        }
      } catch (error) {
        showNotify("刷新失败");
        showNotify({
          message: error
        });
      }
    };
    watch(() => router.currentRoute.value.path, (newValue, oldValue) => {
      let path = router.currentRoute.value;
      if (!headShow.value) {
        return false;
      }
      title.value = path.meta.title;
      isShow.value = path.meta.isShow;
    }, {
      immediate: true
    });
    const init = () => {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf("micromessenger") > -1) {
        headShow.value = false;
      } else {
        headShow.value = true;
      }
    };
    onMounted(() => {
      init();
    });
    return {
      isShow,
      title,
      loading,
      onRefresh,
      headShow,
      refreshDisabled
    };
  }
};