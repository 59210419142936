import router from "@/router";
import { watch, ref } from "vue";
export default {
  props: ["isShow"],
  setup(props, context) {
    let isShow = ref(props.isShow);
    const gototop = () => {
      window.history.go(-1);
    };
    watch(() => props.isShow, (newValue, oldValue) => {
      isShow.value = newValue;
    });
    return {
      isShow,
      gototop
    };
  }
};