import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "header_box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "header_zw"
  }, null, -1)), _createElementVNode("div", _hoisted_1, [$setup.isShow ? (_openBlock(), _createElementBlock("i", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.gototop && $setup.gototop(...args)),
    class: "iconfont icon-right-1-copy"
  })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 64);
}