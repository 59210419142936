import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "../views/Layout.vue";

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "/",
        name: "moren",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "首页",
        },
        component: () => import("@/views/home/index"),
      },
      {
        path: "/home",
        name: "home",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "首页",
        },
        component: () => import("@/views/home/index"),
      },
      {
        path: "/menpiao",
        name: "menpiao",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "旅游卡",
        },
        component: () => import("@/views/menpiao/index"),
      },
      {
        path: "/me",
        name: "me",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "个人中心",
        },
        component: () => import("@/views/me/index"),
      },
      {
          path: "/gsshouyi",
          name: "gsshouyi",
          meta: {
              requiresAuth: true,
              title: "收益统计",
          },
          component: () => import("@/views/carry/gsshouyi"),
      },
      {
        path: "/shop",
        name: "shop",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "兑换区",
        },
        component: () => import("@/views/shop/index"),
      },
      {
        path: "/shop/detail",
        name: "detail",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "商品详细",
        },
        component: () => import("@/views/shop/detail"),
      },
      {
        path: "/shop/jieshuan",
        name: "jieshuan",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "收银台",
        },
        component: () => import("@/views/shop/jieshuan"),
      },
      {
        path: "/address",
        name: "address",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "地址管理",
        },
        component: () => import("@/views/address/index"),
      },
      {
        path: "/updateAddress",
        name: "updateAddress",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "设置地址",
        },
        component: () => import("@/views/address/updateAddress"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "用户订单",
        },
        component: () => import("@/views/order/index"),
      },
      {
        path: "/youpintuan",
        name: "youpintuan",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "秒杀",
        },
        component: () => import("@/views/youpintuan/index"),
      },
      {
        path: "/teams",
        name: "teams",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "我的好友",
        },
        component: () => import("@/views/teams/index"),
      },
      {
        path: "/income",
        name: "income",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "宝珠明细",
        },
        component: () => import("@/views/income/index"),
      },
      {
        path: "/carry",
        name: "carry",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "提现",
        },
        component: () => import("@/views/carry/index"),
      },
      {
        path: "/carryrecord",
        name: "carryrecord",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "提现记录",
        },
        component: () => import("@/views/carry/carryrecord"),
      },
      {
        path: "/setbaozhu",
        name: "setbaozhu",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "转账/记录",
        },
        component: () => import("@/views/setBaozhu/index"),
      },
      {
        path: "/myinvite",
        name: "myinvite",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "邀请二维码",
        },
        component: () => import("@/views/myinvite/index"),
      },
      {
          path: "/coupons",
          name: "coupons",
          meta: {
              requiresAuth: false,
              isShow: true,
              title: "旅游卡商城",
          },
          component: () => import("@/views/coupons/index"),
      },
      {
          path: "/integral",
          name: "integral",
          meta: {
              requiresAuth: false,
              isShow: true,
              title: "旅游卡兑换区",
          },
          component: () => import("@/views/integral/index"),
      },
      {
          path: "/couponCashier",
          name: "couponCashier",
          meta: {
              requiresAuth: false,
              isShow: true,
              title: "收银台",
          },
          component: () => import("@/views/coupons/Cashier"),
      },
      {
        path: "/updatepw",
        name: "updatepw",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "修改密码",
        },
        component: () => import("@/views/me/updatepw"),
      },
      {
        path: "/ai",
        name: "ai",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "AI",
        },
        component: () => import("@/views/ai/index"),
      },
      {
        path: "/gift",
        name: "gift",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "礼品区",
        },
        component: () => import("@/views/coupons/gift"),
      },
      {
        path: "/baoping",
        name: "baoping",
        meta: {
          requiresAuth: false,
          isShow: false,
          title: "爆品区",
        },
        component: () => import("@/views/menpiao/baoping"),
      },
      {
          path: "/upshop",
          name: "upshop",
          meta: {
              requiresAuth: false,
              isShow: false,
              title: "兑换区",
          },
          component: () => import("@/views/shop/upindex"),
      },
      {
        path: "/xibaoshui",
        name: "xibaoshui",
        meta: {
          requiresAuth: false,
          isShow: true,
          title: "细胞水简介",
        },
        component: () => import("@/views/coupons/xibaoshui"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login"),
    meta: {
      requiresAuth: true,
      title: "登录",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register"),
    meta: {
      requiresAuth: true,
      title: "注册",
    },
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () => import("@/views/login/forgotPassword"),
    meta: {
      requiresAuth: true,
      title: "修改密码",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register"),
    meta: {
      requiresAuth: true,
      title: "注册",
    },
  },
  {
    path: "/yhxy",
    name: "yhxy",
    component: () => import("@/views/login/yhxy"),
    meta: {
      requiresAuth: true,
      title: "用户协议",
    },
  },
  {
    path: "/yszc",
    name: "yszc",
    component: () => import("@/views/login/yszc"),
    meta: {
      requiresAuth: true,
      title: "隐私政策",
    },
  },
  {
      path: "/tianfutong",
      name: "tianfutong",
      meta: {
          requiresAuth: true,
          isShow: true,
          title: "冬季旅游 · 惠民福利",
      },
      component: () => import("@/views/TianfuTong/index"),
  },
  {
      path: "/tfxx",
      name: "tfxx",
      meta: {
          requiresAuth: true,
          isShow: true,
          title: "详细",
      },
      component: () => import("@/views/TianfuTong/detail"),
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/views/download"),
    meta: {
      requiresAuth: true,
      title: "选择",
    },
  },
  {
    path: "/kimi",
    name: "kimi",
    component: () => import("@/views/kimi"),
    meta: {
      requiresAuth: true,
      title: "kimiAI",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "流量工具";

  if (to.meta.requiresAuth) {
    next(); // 允许通过
  } else {
    let token = localStorage.getItem("token");
    if (token == null || token == "" || token == undefined) {
      router.push("/Login");
    } else {
      next(); // 允许通过
    }
    // next(); 
  }
});
export default router;
